import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import Layout from "../layouts/main"
import SEO from "../components/seo"
import { device } from "../../lib/Devices"
// import ProductListingItem from "../components/ProductListingItem"
import SectionLink from "../components/SectionLink"

const HeroImg = styled(Img)`
  height: calc(100vh);
`

const IndexSection = styled.section`
  margin: 0 auto;
  width: 100%;
  padding: 15px 15px 0;
  max-width: 1600px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  h2 {
    font-size: calc(5vw + 2rem);
    @media ${device.tablet} {
      font-size: calc(7.5vw + 2rem);
    }
    padding: 0;
    margin: 0 0 5px;
    color: #313332;
    line-height: calc(8vw + 2rem);
    font-style: italic;
    font-weight: 300;
  }
  p {
    font-size: calc(1vw + 1rem);
    line-height: calc(2vw + 1rem);
    max-width: 85%;
    @media ${device.tablet} {
      max-width: 50%;
    }
    margin: 50px 0 50px auto;
    text-align: right;
  }
`

const MainImg = styled(Img)`
  width: 100%;

  margin: 0 auto;
`

const AboutSection = styled.section`
  width: 100vw;
  background: #e7dfd4;
  section {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    padding: 45px;
    margin: 0 auto;

    @media ${device.tablet} {
      flex-direction: row;
      justify-content: space-between;
    }
    aside {
      color: #ff5400;
      font-style: italic;
      text-align: center;
      @media ${device.tablet} {
        width: 40%;
        text-align: left;
      }
      p {
        margin-bottom: 40px;
        font-size: calc(1vw + 1rem);
        line-height: calc(2vw + 1rem);
      }
    }
  }
`

const AboutImg = styled(Img)`
  display: none;
  width: 30%;
  @media ${device.tablet} {
    display: block;
  }
`

// const FeaturedSection = styled.section`
//   width: 100%;
//   max-width: 1600px;
//   margin: 0 auto;
//   padding: 40px 0;
//   .featured-heading {
//     margin: 0 auto;
//     text-align: center;
//     font-size: calc(2.2vw + 2rem);
//     font-style: italic;
//   }
//   section {
//     display: flex;
//     flex-wrap: wrap;
//     align-items: center;
//     justify-content: space-evenly;
//   }
// `

const BespokeSection = styled(BackgroundImage)`
  min-height: 50vh;
  width: 100vw;
  div {
    padding: 15px;
    width: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    section {
      width: 100%;
      max-width: 1000px;
      color: #fffbf4;
      font-style: italic;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media ${device.laptop} {
        display: block;
        text-align: left;
      }
      h3 {
        font-size: calc(2.2vw + 2rem);
      }
      p {
        font-size: calc(1vw + 1rem);
        line-height: calc(2vw + 1rem);
        max-width: 1000px;
        margin-bottom: 50px;
      }
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "scene1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      main: file(relativePath: { eq: "bird1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      about: file(relativePath: { eq: "IMG_1543.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bespoke: file(relativePath: { eq: "Jacket2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      # GET_FEATURED_PRODUCTS: allSanityProduct(
      #   filter: { tags: { eq: "featured" } }
      # ) {
      #   edges {
      #     node {
      #       id
      #       slug {
      #         current
      #       }
      #       title
      #       basePrice
      #       images {
      #         asset {
      #           fluid(maxWidth: 400, maxHeight: 400) {
      #             ...GatsbySanityImageFluid
      #           }
      #         }
      #       }
      #     }
      #   }
      # }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <HeroImg
        fluid={data.hero.childImageSharp.fluid}
        alt="Chainstitch Yorkshire Scene"
      />
      <IndexSection>
        <h2>Custom Chainstitch Embroidery.</h2>
        <p>
          Wearable art pieces and accessories.
          <br />
          <br /> Embroidered using tranditional chainstich techniques.
          <br />
          <br />
          From the southern US to Leeds, UK.
        </p>
      </IndexSection>
      <MainImg
        fluid={data.main.childImageSharp.fluid}
        alt="Chainstitch Bird Design"
      />
      <AboutSection>
        <section>
          <AboutImg
            fluid={data.about.childImageSharp.fluid}
            alt="An embroidered jacket in progress"
          />
          <aside>
            <p>
              Learn about chainstitch embroidery and the process used to make
              fully custom garments and accessories.
            </p>
            <SectionLink route="about" title="Learn More" />
          </aside>
        </section>
      </AboutSection>
      {/* <FeaturedSection>
        <h3 className="featured-heading">Featured Products</h3>
        <section>
          {data.GET_FEATURED_PRODUCTS.edges.map(product => (
            <ProductListingItem key={product.node.id} item={product.node} />
          ))}
        </section>
      </FeaturedSection> */}
      <BespokeSection
        fluid={data.bespoke.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div>
          <section>
            <h3>Bespoke</h3>
            <p>
              From full western wear suits to your own design on a denim jacket,
              get the garment of your dreams.
            </p>
            <SectionLink route="bespoke" title="Start Here" />
          </section>
        </div>
      </BespokeSection>
    </Layout>
  )
}

export default IndexPage
