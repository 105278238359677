import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  border-radius: 10px;
  background: #ff5400;
  padding: 15px;
  font-size: calc(1vw + 1rem);
  color: #e7dfd4;
  font-weight: 300;
  transition: 0.3s ease all;
  border: 2px #ff5400 solid;
  box-sizing: border-box;
  display: inline;
  &:hover {
    background: none;
    color: #ff5400;
  }
`

const SectionLink = ({ route, title }) => {
  return <StyledLink to={`/${route}`}>{title}</StyledLink>
}

export default SectionLink
